import React, { createContext, useContext, useState } from 'react';

const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [iDelegado, setDelegado] = useState(null);
  const [iContacto, setContacto] = useState(null);  
  

  const contextValue = {
    iDelegado,
    setDelegado,
    iContacto,
    setContacto,  
  };

  return (
    <MyContext.Provider value={contextValue}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error('useMyContext debe ser utilizado dentro de un MyContextProvider');
  }
  return context;
};
