import React from 'react'; 
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './index.css';
import Loading from './components/Loading';
import Footer from './Pages/Footer';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './access-in/AuthContext';
import { MyContextProvider } from './access-in/Providercontex'; // Importa MyContextProvider

const App = React.lazy(() => import('./App'));
const history = createBrowserHistory();
const rootElement = document.getElementById('root');
 
createRoot(rootElement).render(
  <React.StrictMode>  
    <AuthProvider>   
      <MyContextProvider> {/* Agrega MyContextProvider aquí */}
        <React.Suspense fallback={<Loading />}>
          <Router history={history}>
            <App /> 
            <Footer />
          </Router>   
        </React.Suspense>
      </MyContextProvider> {/* Cierre de MyContextProvider */}
    </AuthProvider>
  </React.StrictMode>,  
  rootElement
);
   
reportWebVitals();
